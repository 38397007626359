
<i18n locale="th" lang="yaml" >
 page.title : "{name} :: Duplicate Drone Model"
 page.description : "ทำการสร้าง Drone Model ใหม่โดยใช้ข้อมูลแบบรุ่น {name}"
 duplicate_model.success : "ทำการสร้าง Drone รุ่น {name} ในระบบเรียบร้อย"
 duplicate_model.submit.label : "เพิ่มรุ่นใหม่"
 duplicate_model.confirm.title : "ยืนยันการสร้าง Drone Model"
 duplicate_model.confirm.message : "คุณต้องการสร้าง Drone Model ใหม่?"
</i18n>

<template>
	<div class="page page-padding">
		<my-page-header
			:title="$t('page.title',{ name: model.name })"
			:description="$t('page.description',{ name: model.name })"/>

		<DroneModelForm
			ref="formRef"
			:loading="loading"
			mode="create"
			@submit="handleSubmit">
			<template v-slot:submitSlot="{ formLoading }">
				<a-button
					:disabled="formLoading"
					icon="left"
					size="large"
					:style="{ 'margin-right': '4px' }"
					@click="goBack">
					{{$t('common.back')}}
				</a-button>
				<a-button
					:loading="formLoading"
					type="info"
					html-type="submit"
					class="btn-submit"
					icon="edit"
					size="large">
					{{$t('duplicate_model.submit.label')}}
				</a-button>
			</template>
		</DroneModelForm>
	</div>
</template>

<script>
import PageMixin from '@mixins/PageMixin.vue'
import DroneModelForm from '@components/drone/DroneModelForm.vue'
import axios from "axios"

export default {
	page() {
		return {
			title : this.$t('page.title',{name : this.model.name})
		}
	} ,
	components : {
		DroneModelForm,
	} ,
	mixins: [PageMixin],
	data(){
		return {
			model : {} ,
			loading : false,
		}
	} ,
	watch: {
		$route : 'fetchData' ,
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		fetchData() {
			const modelId = this.$route.params.id;
			if (!modelId)
				return

			this.showPageLoading(true)
			this.clearBreadcrumbParams('modelName')
			axios.get("/api/drone-models/"+modelId).then((response)=>{
				this.model = response.data.data.drone_model;
				const modelForm = {...this.model}
				modelForm.name = modelForm.name + " [Copy]"
				modelForm.image_url = undefined
				modelForm.release_date = undefined
				this.$refs.formRef.updateModelForm(modelForm)

				this.addBreadcrumbParams({'modelName' : this.model.name})
			}).catch((error) => {
				this.fetchError(error);
			}).finally(()=>{
				this.hidePageLoading()
			})
		} ,
		handleSubmit(formData) {
			this.$confirm({
				title : this.$t('duplicate_model.confirm.title') ,
				content : this.$t('duplicate_model.confirm.message') ,
				maskClosable : true,
				onOk : () => {
					this.loading = true
					axios.post("/api/drone-models/"+this.model.id+"/duplicate",formData).then((response) => {
						const newModel = response.data.data.drone_model
						this.$open({name : 'drone_model/view',params : {id : newModel.id}});
						this.$message.success(this.$t('duplicate_model.success',{"name" : newModel.name}))
					}).catch((error) => {
						this.$refs.formRef.formErrors(error)
					}).finally(()=>{
						this.loading = false;
					});
				}
			})
		} ,
		goBack() {
			this.$open({name:'drone_model/view',params:{id:this.model.id}})
		}
	} ,
}
</script>
